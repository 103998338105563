@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
}

a{
    display: block;
}